@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap");
#root{
  background-color: #FBFBFB;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-family: "Poppins", sans-serif;
}
.messageTop {
  background-color: #9c8a68;
}
.messageTop p {
  text-align: center;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0.5rem;
}
.logoImg {
  width: 150px;
  height: 70px;
}
.badge {
  font-size: 5px !important;
  position: relative !important;
  top: -12px !important;
  color: red !important;
}
.TopProduct{
  margin-bottom: 10%;
}

.TopProduct .carousel {
  height: auto !important;
  /* background: aquamarine; */
  padding: 10px !important;
}
.TopProduct .divInfo {
  display: grid;
  align-content: space-evenly;
}
.TopProduct .divInfo h1 {
  font-size: 5rem;
  color: #000;
  font-weight: initial;
}
.TopProduct .divInfo h2 {
  font-size: 3rem;
  color: #000;
  font-weight: initial;
  letter-spacing: 6px;
  position: relative;
  top: -30px;
}
 .dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}
.TopProduct .divImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TopProduct .divImg img {
  height: 445px;
  width: 354px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(90%) sepia(28%) saturate(6047%) hue-rotate(339deg)
    brightness(103%) contrast(84%);
}
.carousel-indicators [data-bs-target] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ebaa33;
}
/* Categorie */
.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 1 / 1 / 3 / 2;
  position: relative;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
  background-color: #f6f6f6;
  position: relative;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
  background-color: #f6e8e1;
  position: relative;
}
.div4 {
  grid-area: 2 / 2 / 3 / 3;
  background-color: #ffe8fd;
  position: relative;
}
.div5 {
  grid-area: 2 / 3 / 3 / 4;
  background-color: #d7d9d0;
  position: relative;
}

.Categorie {
  height: 500px;
  margin-bottom: 3%;

}
.Categorie a {
  color: #000;
  text-decoration: none;
}

.Categorie .grandImg {
  width: 100%;
  height: 100%;
}
.Categorie .smallImg  {
  width: 60%;
float: right;
}
.Categorie .catInfo {
  position: absolute;
  top: 5%;
  left: 3%;
}
.Categorie .catInfo h1 {
  font-size: 30px;
  margin-bottom: 0;
}
.ListProducts{
  margin-bottom: 3%;
}

.fancy-undeline {
  background-image: linear-gradient(120deg, #9C8A68,#9C8A68 100%);
  /* background-color: #9C8A68; */
  background-repeat: no-repeat;
  background-size: 128px .15em;
  background-position: 50% 100%;
  transition: background-size 0.25s ease-in;
  padding-bottom: 7px;
}
.ListProducts .product {
  position: relative;
}
.ListProducts .product-info {
  margin: 10px 0;
}

.ListProducts .product-info .titreProduct{
  font-size: 20px;
  color: #888181;
  margin-bottom: 0;
  font-weight: normal;
}

.ListProducts .product-info .prix{
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
  font-weight: normal;
}
.ListProducts .divnew{
  background-color: #1ebe0c;
  padding: 5px;
  color: #fff;
  position: absolute;
  top: 15px;
  left: 0px;
  /* font-size: 20px; */
}
.ListProducts .divprix {
  background-color: #ff0000;
  padding: 10px 5px;
  color: #fff;
  position: absolute;
  top: 0px;
  right: 20px;

}
.Meilleurvente{
  margin-bottom: 5%;
}
.CovertureImg {
  width: 100%;
  height: 100%;
  background-image:linear-gradient(270deg, rgba(235, 170, 52, 0), rgba(235, 170, 52, 0.5)), url('./images/GettyImages-691910415-700x467.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0;
position: relative;
}
