@import "./variables.scss";


.thumbnails {
    border: 1px solid $color3;
    padding: 2px;
}

* {
    font-family: $font  !important;
}

#orig-product {

    #breadcumb {
        margin: 15px 0 30px;

        a {
            color: $color3;

            &:not(:first-child) {
                margin-left: 7.5px;

                &:before {
                    content: '/';
                    margin-right: 7.5px;
                    font-weight: 400;
                }
            }

            &:last-child {
                font-weight: 700;
            }
        }
    }

    .column {
        float: left;
        width: 50%;
    }

    #column-2 {
        display: flex;
        justify-content: center;
    }

    #gallery {
        float: left;
        width: 82px;

        img {
            width: 100%;
            opacity: .5;

            &.active {
                opacity: 1;
            }
        }

        #video {
            position: relative;
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 28px;
                width: 100%;
                height: 45px;
                background: url(../../assets/svg/play.svg) no-repeat center;
                z-index: 1;
            }

            p {
                margin: 0;
                text-align: center;
                color: $color1;
            }

            img {
                height: 45px;
                object-fit: cover;
            }
        }

        #thumbnails {
            .slick-arrow {
                width: 100%;
                padding: 0;
                border: 0;
                font-size: 0;

                &.slick-prev {
                    margin-bottom: 12px;
                    background: url(../../assets/svg/arrow-up.svg) no-repeat center;
                }

                &.slick-next {
                    margin-top: 2px;
                    background: url(../../assets/svg/arrow-down.svg) no-repeat center;
                }
            }

            .slick-list {
                line-height: 1.1;
                height: 520px;

                .slick-slide {
                    >div {
                        margin-bottom: 10px;
                        border: 1px solid $color3;
                        padding: 1px;
                    }
                }
            }
        }
    }

    #image {
        display: inline-block;
        justify-content: center;
    }

    #details {
        padding: 20px;

        .name {
            margin-bottom: 5px;
            font-size: 26px;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            color: $color2;
        }

        .sku {
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 20px;
            color: $color2;
        }

        .reference {
            font-weight: 900;
            color: $color4;
            font-size: $font-size-xs;
        }

        .prod-details {
            position: relative;
            display: flex;

            .lb {
                border-right: 1px solid $color4;
                height: 28px;
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }

        .price {

            span {
                font-size: 14px;
                line-height: 20px;
                text-decoration: line-through;
                color: #e35442;

                &:after {
                    content: '|';
                    display: inline-block;
                    margin-left: 5px;
                    margin-right: 10px;
                }
            }

            strong {
                font-size: 20px;
                line-height: 32px;
            }
        }

        .installment {
            margin-bottom: 35px;
            line-height: 1;
            color: #7f7f7f;
        }

        .variations {
            margin-bottom: 45px;

            .attribute {
                span {
                    font-size: 14px;
                    line-height: 20px;
                    color: $color2;
                }

                strong {
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #5f75aa;
                }

                input {
                    display: none;
                }

                a {
                    float: right;
                    font-size: 14px;
                    line-height: 20px;
                    color: $color1;
                }

                .color {
                    input {
                        &:checked {
                            +label {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: -3px;
                                    left: -3px;
                                    width: 26px;
                                    height: 26px;
                                    border: 2px solid $color1;
                                    border-radius: 100%;
                                }
                            }
                        }
                    }

                    label {
                        position: relative;
                        width: 22px;
                        height: 22px;
                        margin-left: 15px;
                        border: 1px solid #fff;
                        border-radius: 100%;
                        cursor: pointer;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }
                }

                .size {
                    input {
                        &:checked {
                            +label {
                                background-color: $color1;
                                color: #fff;
                            }
                        }
                    }

                    label {
                        width: 34px;
                        margin-left: 5px;
                        border: 1px solid #ebebeb;
                        border-radius: 2px;
                        font-size: 14px;
                        line-height: 34px;
                        text-align: center;
                        color: #5f75aa;
                        cursor: pointer;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .buy {
            button {
                width: 100%;
                margin-bottom: 20px;
                border-radius: 3px;
                background-color: $color5;
                font-size: 18px;
                line-height: 60px;
                text-transform: uppercase;
                color: #fff;
            }
        }

        .description {
            font-size: 14px;
            color: $color3;
        }
    }

    #related {
        margin-bottom: 145px;
        padding-top: 40px;
        clear: both;

        #title {
            margin-bottom: 15px;
            font-size: 26px;
            line-height: 38px;
            text-align: center;
            text-transform: uppercase;
            color: $color1;
        }

        #showcase {
            .slick-arrow {
                position: absolute;
                bottom: -35px;
                width: 35px;
                height: 35px;
                padding: 0;
                border: 2px solid #2a5a75;
                border-radius: 100%;
                font-size: 0;

                &.slick-prev {
                    left: calc(50% - 105px);
                    background: url(../../assets/svg/arrow-left.svg) no-repeat center;
                }

                &.slick-next {
                    right: calc(50% - 105px);
                    background: url(../../assets/svg/arrow-right.svg) no-repeat center;
                }
            }

            .count {
                display: block;
                font-size: 14px;
                line-height: 35px;
                text-align: center;
                color: #2a5a75;
            }

            .product {
                padding-right: 10px;

                .image {
                    img {
                        width: 100%;
                    }
                }

                .price {
                    float: left;
                    font-size: 16px;
                    line-height: 23px;
                    color: $color2;
                }

                .variations {
                    float: right;

                    span {
                        display: inline-block;
                        width: 13px;
                        height: 13px;
                        border-radius: 100%;

                        &:not(:first-child) {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $lg) {}

    @media (max-width: $md) {

        .filter-container {
            width: 100% !important;
        }

        .w-md-100 {
            width: 100% !important;
        }

        #breadcumb {
            display: none;
        }

        .column {
            float: none;
            width: auto;
        }

        #gallery {
            position: relative;
            float: none;
            width: auto;

            img {
                opacity: 1;
            }

            #video {
                position: absolute;
                left: 10px;
                bottom: 10px;
                width: 42px;
                height: 41px;

                &:before {
                    top: auto;
                    width: 42px;
                    height: 41px;
                }

                p,
                img {
                    display: none;
                }
            }

            #thumbnails {
                border: 1px solid #aeb6c5;
                padding: 2px;

                .slick-dots {
                    position: absolute;
                    bottom: 25px;
                    width: 100%;
                    text-align: center;
                    line-height: 0;

                    li {
                        display: inline-block;
                        margin-left: 20px;

                        button {
                            width: 10px;
                            height: 10px;
                            border: 1.5px solid #fff;
                            border-radius: 100%;
                            font-size: 0;
                        }

                        &.slick-active {
                            button {
                                border: 0;
                                background-color: $color1;
                            }
                        }
                    }
                }
            }

            .slick-list {
                height: auto !important;
            }

        }

        #image {
            display: none;
        }

        #details {
            display: flex;
            flex-direction: column;
            padding: 0;
            border: 0;

            .mobile-header {
                position: absolute;
                left: 0;
                top: 70px;
                right: 0;
                text-align: center;

                .name {
                    font-size: 18px;
                    font-weight: 400;
                    color: $color1;
                }


            }

            .price {
                font-weight: 900;
            }

            .installment {
                margin-bottom: 20px;
                order: 3;
            }

            .variations {
                margin-bottom: 10px;
                order: 1;
            }

            .buy {
                order: 4;
            }

            .description {
                order: 5;
            }

            .reference {
                font-weight: 900;
            }
        }

        #related {
            margin-bottom: 40px;

            #showcase {
                .product {
                    .variations {
                        display: none;
                    }
                }
            }
        }

        .slick-list {

            .slick-slide {
                >div {
                    border: 0px !important;
                }
            }
        }

        #thumbnails {
            border: 0px !important;
        }

    }

    input[type="radio"] {
        display: none;

        &:checked+.color-picker {
            span {
                transform: scale(1.25);
                position: absolute;
                top: 14%;
                right: 15%;
            }

            border: 2px solid $color4;
            border-radius: 50%;

            // !@each
        }

        // !&:checked + label
    }

    .color-picker {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        position: relative;
        cursor: pointer;

        &:hover {
            span {
                transform: scale(1.25);
            }
        }

        span {
            display: block;
            width: 70%;
            height: 70%;
            transition: transform .2s ease-in-out;
            border-radius: 50%;
            background: $color4;


        }
    }

}



.shopnow {
    border: 0;
    background-color: $color1;
    color: white;
    padding: 6px 85px;
    border-radius: 50px;
    font-size: $font-size-md;
    font-weight: bold;
}

.cancel {
    border: 0;
    background-color: $color4;
    color: white;
    padding: 6px 85px;
    border-radius: 50px;
    font-size: $font-size-md;
    font-weight: bold;
}

.quantity {
    border-radius: 50px;
    padding: 2px 10px;
}

.price:not(p) {
    font-weight: bold;
    font-size: $font-size-md;
}

.card {
    margin: auto;
    max-width: 350px;
    width: 90%;
    height: 87%;
    border-radius: 0rem;
    border: transparent;
    align-items: center;

    .card-title {
        color: $color4;
        font-size: $font-size-xs;
    }

    .product-img {
        width: 346px;
        height: 430px;
        box-shadow: 0 0px 8px 0 rgb(0 0 0 / 6%);
        overflow: hidden;
        margin: 0 auto;
    }

    .product-img img {
        width: 100%;
        transition: 0.5s all ease-in-out;
    }

    .product-img img:hover {
        transform: scale(1.5);
    }
}

@media(max-width:767px) {
    .card {
        margin: 3vh auto;
    }

    .product-img {
        width: 266px !important;
        height: 350px !important;
    }


}

.cart {
    background-color: #fff;
    padding: 4vh 5vh;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
}

@media(max-width:767px) {
    .cart {
        padding: 4vh;
        border-bottom-left-radius: unset;
        border-top-right-radius: 1rem;
    }
}

.summary {
    background-color: #ddd;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 4vh;
    color: rgb(65, 65, 65);
}

@media(max-width:767px) {
    .summary {
        border-top-right-radius: unset;
        border-bottom-left-radius: 1rem;
    }
}

.summary .col-2 {
    padding: 0;
}

.summary .col-10 {
    padding: 0;
}

.row {
    margin: 0;
}

.title b {
    font-size: 1.5rem;
}

.main {
    margin: 0;
    padding: 2vh 0;
    width: 100%;
}

.col-2,
.col {
    padding: 0 1vh;
}

a {
    padding: 0 1vh;
}

.close {
    margin-left: auto;
    font-size: 0.7rem;
}

.back-to-shop {
    margin-top: 4.5rem;
}

h5 {
    margin-top: 4vh;
}

hr {
    margin-top: 1.25rem;
}

form {
    padding: 2vh 0;
}

select {
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1.5vh 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 50%;
    background-color: rgb(247, 247, 247);
}

input {
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 100%;
    background-color: rgb(247, 247, 247);
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

.btn {
    background-color: #000;
    border-color: #000;
    color: white;
    width: 100%;
    font-size: 0.7rem;
    margin-top: 4vh;
    padding: 1vh;
    border-radius: 0;
}

.btn:focus {
    box-shadow: none;
    outline: none;
    box-shadow: none;
    color: white;
    -webkit-box-shadow: none;
    -webkit-user-select: none;
    transition: none;
}

.btn:hover {
    color: white;
}

a {
    color: black;
}

a:hover {
    color: black;
    text-decoration: none;
}

#code {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.253), rgba(255, 255, 255, 0.185)), url("https://img.icons8.com/small/16/000000/long-arrow-right.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
}

input {
    line-height: 2.5 !important;
    border-radius: 0% !important;
}

label {
    color: $color4;
}

.btn-close {
    background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAADlUlEQVRYhe2WS2wbVRSGvzMzTitV6YqmcR4SlC66CBvKxsmkQlRs6nFcq+yyQd014iHR16oo7GgFAiSIEAtWsCrEzcONQCqqiGs2PBZEgk2pkJOM611bqgZn5h4WdiJnPImTwI78qyufc8//zcy5vgf29H+X7CR5KXui3wrDLCJplKeB/kaoDNwDCmHoTPXN3V78TwHKXqo3IfbbqpwFnDbpBuFrDc3FnpulP/81QCU9NKIiXwCdQA0kL8qN0Da/2CsHFgHC/Y/7bJXnVSULmgM6gEcCo92zxZldA1TSw6+r6IeAhXBdsC53z3x/b6s999PuESN6DeQMYEDfTM7e+XjHAI0nzwOqIhd7ZuY/2Mo4Kj/tnke4CojA6c3eRCxA2Uv1Oti/AZ0q8tZOzSMQ7wEPnVU5duibeT+aY8VttNV+B+hEuL5bc4Bkofg+wiRwMOzQ8bicFoCl7Il+EV4FaoJ1ORr308PnKrnBrujvldxgl58ePtdiEJhLQE2Vs2Uv1dsWwArDLGCD5KMN52fcMUQndNW61QxRyQ126ap1C9EJP+OONe85PFe6izAFOI7Y2bYAIKcARHSqJeKYr4AFYGANYt0cBoCFRs4Gqa7XOrUNAJ4FCEJ+iga686WqJMzJZogN5glzsjtfqrYAWPxYX9RrtwPoAeg44CzHxOjOl6qE8hLCrw3jAeB32zgvx5kDJPYllhrLvu0AaFyRdpIw3PQ/xf7ryZqP2Q6AD1B7HPTEFavkBruw9TuU56h/igXgWJDQb+NOB8BKwkk2li1vNQ7gLoBjczzOPPrNoz0RByGGF+qLeu12AAWA+sWyURpYrxBpuJbGrOdsBJBGLSOFtgBh6EwBAWjufto90hxLzhQnUBmLdvs6hMpYcqY40bynOpI6ipIFgkCC6ZgHblXFcz/zPVd9b6jlTO9UFc+d9D1XK2n307h4/F1g6TjwCOSMn3bP79bczwxdUMghPLDU2d5dAHBo+s6ywCj16ebqbiD8zNAFVN4FDEZGu27ersTlbTmQ+N7QayAfUR9IJq3AXDo8V2rp5GZVR1JHjbGvKeSon/s3krPFTzbLbz+SZVxPlS+Bg0ANYUrghiH82dm/bxEgWPm7D7WOC3IaIYuSQHiAkdFkYb6l83cEALCcefEpNLgiMEb7oTQQ4XO7JuNxA8iuANZU9lK9jjojCB7oMzSP5cIfGCkEEkz3z/6wtFWdPe2pWf8AOF16TFi6xPgAAAAASUVORK5CYII=") no-repeat center;
    margin: 54px 41px 0px 0px !important;
}

.smallImg {
    width: 150px;
    height: 250px;
}

.categories {


    .slick-slide {
        height: 400px;
        box-sizing: border-box;
        text-align: center;

        div:first-child {
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .slick-slide img {
        width: 174px;
        height: 238px;
        box-sizing: border-box;
        box-shadow: 1px 1px 1px 1px lightgrey;
    }

    .desc {
        top: 9px;
        left: 5px;
    }
}

.CovertureImg {
    color: #fff;
    padding: 65px 90px 73px 118px;
}

footer {
    background: black;
    color: white;

    a {
        color: #fff;
        text-decoration: none;
    }
}


ul.social-footer2 {
    margin: 0;
    padding: 0;
    width: auto;
}

ul.social-footer2 li {
    display: inline-block;
    padding: 0;
}

ul.social-footer2 li a:hover {
    background-color: #ff8d1e;
}

ul.social-footer2 li a {
    display: block;
    height: 30px;
    width: 30px;
    text-align: center;
}

.btn {
    background-color: #ff8d1e;
    color: #fff;
}

.btn:hover,
.btn:focus,
.btn.active {
    background: #4b92dc;
    color: #fff;
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 250ms ease-in-out 0s;
    -moz-transition: all 250ms ease-in-out 0s;
    -ms-transition: all 250ms ease-in-out 0s;
    -o-transition: all 250ms ease-in-out 0s;
    transition: all 250ms ease-in-out 0s;

}

.filter-container {
    width: 50% !important;
}


.carousel-control-prev {
    left: 50% !important;
}


.side-bar {
    .position-fixed {
        z-index: 100;
    }
}

.productInfo {
    text-align: left;
}

@media (max-width: $md) {

    .filter-container {
        width: 100% !important;
    }

    .productInfo {
        text-align: center;
    }

    .carousel-control-prev {
        left: 0% !important;
    }

    .side-bar {
        .position-fixed {
            left: 0 !important;
        }
    }

    .TopProduct {
        text-align: center !important;
    }

    #orig-product {
        text-align: center !important;
    }

    .categories {
        height: 280px;
    }

    .CovertureImg {
        color: #fff;
        padding: 65px 0px 60px 0px;
    }
}

.best-selling {
    width: 100%;
    height: 324px;
}

.txt-limit-3 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}