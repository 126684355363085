// Fonts
$font: "Poppins", sans-serif;

$font-size-xxs : 0.6rem;
$font-size-xs : 0.8rem;
$font-size-sm : 1rem;
$font-size-md : 1.2rem;
$font-size-lg : 1.4rem;
$font-size-xl : 1.6rem;



// Breakpoints
$sm: '576px';
$md: '768px';
$lg: '992px';
$xl: '1200px';

// Colors
$color1: #ebaa34;
$color2: #202020;
$color3: #aeb6c1;
$color4: #686868;
$color5: #32917b;

@font-face {
    font-family: "Adine";
    src: url('../../assets//fonts/Adine\ Kirnberg.ttf');
    src: url('../../assets/fonts/AdineKirnberg-Alternate.ttf');
}